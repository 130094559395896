import React, { useEffect, useState } from 'react';
import { ArrowBackIcon } from '@bigcommerce/big-design-icons';
import { H1, Panel, Text, Table, H2, H3 } from '@bigcommerce/big-design';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PageLoading from '../components/PageLoading';
import { Api } from '../services/Api';
import BlogPost from '../components/blog-writer/BlogPost';

const BlogDetail = (props) => {

    const [selectedBlogPost, setSelectedBlogPost] = useState([]);
    const [loading, setLoading] = useState(true);

    let { id } = useParams();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const data = await Api.get("/blogApi-getBlogPost", { id: id });
                setSelectedBlogPost(data);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    return (
        <>
            {loading &&
                <PageLoading />
            }
            {!loading &&
                <>
                    <RouterLink to="/blog-writer" className="no-underline"><Text color="secondary60"><ArrowBackIcon color="secondary60" /> Back</Text></RouterLink>
                    <BlogPost
                        height={600}
                        blogPost={selectedBlogPost}
                    ></BlogPost>
                </>
            }
        </>
    );
}
export default BlogDetail;


