import { Input, Button, Checkbox, StatefulTree, Flex, FlexItem, Modal, Datepicker, Text, Select, Box, CheckboxLabel } from "@bigcommerce/big-design";
import { SearchIcon, FilterListIcon, ArrowDownwardIcon, ArrowUpwardIcon } from '@bigcommerce/big-design-icons';
import { useEffect, useState } from "react";
import { SharedState } from "../../../services/SharedState";
import "./ProductSearchForm.css";

const ProductSearchForm = (props) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [categoryTreeNodes, setCategoryTreeNodes] = useState([]);
    const [tempSelectedCategories, setTempSelectedCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [tempSelectedBrand, setTempSelectedBrand] = useState();
    const [selectedBrand, setSelectedBrand] = useState();
    const [tempImportDateStart, setTempImportDateStart] = useState();
    const [tempImportDateEnd, setTempImportDateEnd] = useState();
    const [tempModificationDateStart, setTempModificationDateStart] = useState();
    const [tempModificationDateEnd, setTempModificationDateEnd] = useState();
    const [tempMinPrice, setTempMinPrice] = useState();
    const [tempMaxPrice, setTempMaxPrice] = useState();
    const [importDateStart, setImportDateStart] = useState();
    const [importDateEnd, setImportDateEnd] = useState();
    const [modificationDateStart, setModificationDateStart] = useState();
    const [modificationDateEnd, setModificationDateEnd] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [onlyNonAiModified, setOnlyNonAiModified] = useState(false);
    const [onlyVisible, setOnlyVisible] = useState(false);
    const [onlyInStock, setOnlyInStock] = useState(false);
    const [tempSortBy, setTempSortBy] = useState("id");
    const [tempSortDirection, setTempSortDirection] = useState("asc");
    const [sortBy, setSortBy] = useState();
    const [sortDirection, setSortDirection] = useState();


    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        if (isFilterModalOpen && categoryTreeNodes.length === 0) {
            let categories = SharedState.getValue("categories");
            // prepare tree nodes
            if (categories) {
                categories = categories.sort((c1, c2) => { return c1.sort_order - c2.sort_order });
                const nodes = prepareNodes(0, categories);
                setCategoryTreeNodes(nodes);
            }

            let brands = SharedState.getValue("brands");
            if (brands) {
                setBrands(brands);
            }
        }
    }, [isFilterModalOpen]);

    const prepareNodes = (parentId, categories) => {
        const topLevelCategories = categories.filter(c => c.parent_id === parentId);
        const nodes = [];
        topLevelCategories.forEach((c) => {
            nodes.push({
                id: c.id,
                value: c.id,
                label: c.name,
                children: prepareNodes(c.id, categories)
            });
        });

        return nodes.length > 0 ? nodes : null;
    }

    const onCategorySelectionChange = (selection) => {
        setTempSelectedCategories(selection);
    }

    const handleBrandChange = (selection) => {
        setTempSelectedBrand(selection)
    }

    const handleSortByChange = (selection) => {
        setTempSortBy(selection)
    }

    const handleSortDirectionChange = (selection) => {
        setTempSortDirection(selection)
    }

    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(keyword, selectedCategories, selectedBrand, tempImportDateStart, tempImportDateEnd, tempModificationDateStart, tempModificationDateEnd, tempMinPrice, tempMaxPrice, onlyNonAiModified, onlyVisible, onlyInStock, tempSortBy, tempSortDirection);
        }
    }

    const onApplyFilter = () => {
        setSelectedCategories([...tempSelectedCategories]);
        setSelectedBrand(tempSelectedBrand);
        setImportDateStart(tempImportDateStart);
        setImportDateEnd(tempImportDateEnd);
        setModificationDateStart(tempModificationDateStart);
        setModificationDateEnd(tempModificationDateEnd);
        setMinPrice(tempMinPrice);
        setMaxPrice(tempMaxPrice);
        setSortBy(tempSortBy);
        setSortDirection(tempSortDirection);
        if (props.onSubmit) {
            props.onSubmit(keyword, tempSelectedCategories, tempSelectedBrand, tempImportDateStart, tempImportDateEnd, tempModificationDateStart, tempModificationDateEnd, tempMinPrice, tempMaxPrice, onlyNonAiModified, onlyVisible, onlyInStock, tempSortBy, tempSortDirection);
        }
        setIsFilterModalOpen(false);
    }

    const isFiltered = () => {
        return selectedCategories.length > 0 || selectedBrand || importDateStart || importDateEnd || modificationDateStart || modificationDateEnd || minPrice || maxPrice || onlyNonAiModified || onlyVisible || onlyInStock || sortBy || sortDirection;
    }

    return (
        <div className="product-search-form">
            <Flex>
                <FlexItem flexGrow={1} marginRight="small">
                    <Input onChange={(e) => setKeyword(e.target.value)} iconLeft={<SearchIcon color="secondary"></SearchIcon>}></Input>
                </FlexItem>
                <Button type="button" variant="secondary" onClick={onSubmit}>Search</Button>
                <div className={isFiltered() ? "filtered" : "unfiltered"}>
                    <Button
                        type="button"
                        variant="subtle"
                        iconOnly={<FilterListIcon color={isFiltered() ? "primary" : "secondary"} />}
                        onClick={() => setIsFilterModalOpen(true)}></Button>
                </div>
            </Flex>
            <Modal
                header="Filter"
                isOpen={isFilterModalOpen}
                onClose={() => {
                    setTempSelectedBrand(selectedBrand);
                    setTempImportDateStart(importDateStart);
                    setTempImportDateEnd(importDateEnd);
                    setTempModificationDateStart(modificationDateStart);
                    setTempModificationDateEnd(modificationDateEnd);
                    setTempMinPrice(minPrice);
                    setTempMaxPrice(maxPrice);
                    setTempSortBy(sortBy);
                    setTempSortDirection(sortDirection);
                    setIsFilterModalOpen(false);
                }
                }
                closeOnEscKey={true}
                closeOnClickOutside={true}
                actions={[
                    {
                        text: 'Cancel',
                        variant: 'subtle',
                        onClick: () => {
                            setTempSelectedBrand(selectedBrand);
                            setTempImportDateStart(importDateStart);
                            setTempImportDateEnd(importDateEnd);
                            setTempModificationDateStart(modificationDateStart);
                            setTempModificationDateEnd(modificationDateEnd);
                            setTempMinPrice(minPrice);
                            setTempMaxPrice(maxPrice);
                            setTempSortBy(sortBy);
                            setTempSortDirection(sortDirection);
                            setIsFilterModalOpen(false);
                        }
                    },
                    {
                        text: 'Apply', onClick: onApplyFilter
                    },
                ]}
            >
                <Text marginTop={"medium"} marginBottom={"none"}><strong>Category</strong></Text>
                {categoryTreeNodes && categoryTreeNodes.length > 0 &&
                    <div className="category-tree">
                        <StatefulTree
                            selectable="multi"
                            nodes={categoryTreeNodes}
                            onSelectionChange={onCategorySelectionChange}
                            defaultSelected={selectedCategories}
                        />
                    </div>
                }
                {brands && brands.length > 0 &&
                    <Select
                        filterable={true}
                        label="Brand"
                        maxHeight={300}
                        onOptionChange={handleBrandChange}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setTempSelectedBrand();
                            }
                        }}
                        options={
                            brands.map((b) => {
                                return {
                                    value: b.id,
                                    content: b.name
                                };
                            })}
                        placement="bottom-start"
                        required
                        value={tempSelectedBrand}
                    />
                }
                {props.filterByDate &&
                    <>
                        <Text marginTop="medium" marginBottom={"none"}><strong>Product Price</strong></Text>
                        <Flex flexGap="8px">
                            <FlexItem>
                                <Input
                                    onChange={(e) => setTempMinPrice(e.target.value)}
                                    value={tempMinPrice}
                                    placeholder="min"
                                />
                            </FlexItem>
                            <FlexItem>
                                <Text>-</Text>
                            </FlexItem>
                            <FlexItem>
                                <Input
                                    onChange={(e) => setTempMaxPrice(e.target.value)}
                                    value={tempMaxPrice}
                                    placeholder="max"
                                />
                            </FlexItem>
                        </Flex>
                        <Text marginTop={"medium"} marginBottom={"none"}><strong>Product Import Date</strong></Text>
                        <Flex flexGap="8px">
                            <FlexItem>
                                <Datepicker
                                    locale="en-US"
                                    onDateChange={(value) => setTempImportDateStart(value)}
                                    value={tempImportDateStart}
                                    placeholder="start"
                                />
                            </FlexItem>
                            <FlexItem>
                                <Text>-</Text>
                            </FlexItem>
                            <FlexItem>
                                <Datepicker
                                    locale="en-US"
                                    onDateChange={(value) => setTempImportDateEnd(value)}
                                    value={tempImportDateEnd}
                                    placeholder="end"
                                />
                            </FlexItem>
                        </Flex>
                        <Text marginTop="medium" marginBottom={"none"}><strong>Product Modification Date</strong></Text>
                        <Flex flexGap="8px">
                            <FlexItem>
                                <Datepicker
                                    locale="en-US"
                                    onDateChange={(value) => setTempModificationDateStart(value)}
                                    value={tempModificationDateStart}
                                    placeholder="start"
                                />
                            </FlexItem>
                            <FlexItem>
                                <Text>-</Text>
                            </FlexItem>
                            <FlexItem>
                                <Datepicker
                                    locale="en-US"
                                    onDateChange={(value) => setTempModificationDateEnd(value)}
                                    value={tempModificationDateEnd}
                                    placeholder="end"
                                />
                            </FlexItem>
                        </Flex>
                    </>
                }
                {props.sort &&
                    <>
                        <Text marginTop={"medium"} marginBottom={"none"}><strong>Sort</strong></Text>
                        <Flex flexGap={"8px"}>
                            <FlexItem>
                                <Select
                                    required
                                    value={tempSortBy}
                                    onOptionChange={handleSortByChange}
                                    options={
                                        [
                                            { value: "id", content: "Product Id" },
                                            { value: "name", content: "Product Name" },
                                            { value: "date_last_imported", content: "Import Date" },
                                            { value: "date_modified", content: "Modification Date" },
                                            { value: "inventory_level", content: "Inventory Level" }
                                        ]
                                    }
                                ></Select>
                            </FlexItem>
                            <FlexItem>
                                <Select
                                    required
                                    onOptionChange={handleSortDirectionChange}
                                    value={tempSortDirection}
                                    options={
                                        [
                                            { value: "asc", content: "asc" },
                                            { value: "desc", content: "desc" },
                                        ]
                                    }
                                ></Select>
                            </FlexItem>
                        </Flex>
                    </>
                }
                {props.filterNonAiModified &&
                    <>
                        <Box marginTop="medium">
                            <Checkbox
                                checked={onlyVisible}
                                label={<CheckboxLabel><strong>Only visible products</strong></CheckboxLabel>}
                                onChange={() => setOnlyVisible(!onlyVisible)}
                            />
                        </Box>
                        <Box marginTop="medium">
                            <Checkbox
                                checked={onlyInStock}
                                label={<CheckboxLabel><strong>Only in-stock products</strong></CheckboxLabel>}
                                onChange={() => setOnlyInStock(!onlyInStock)}
                            />
                        </Box>
                        <Box marginTop="medium">
                            <Checkbox
                                checked={onlyNonAiModified}
                                label={<CheckboxLabel><strong>{props.mode === "FAQ" ? "Only without FAQ" : "Only Non-AI-Modified Products"}</strong></CheckboxLabel>}
                                onChange={() => setOnlyNonAiModified(!onlyNonAiModified)}
                            />
                        </Box>
                    </>
                }
                <input type="text" autoFocus={true} style={{ height: 0, width: 0, border: "none", padding: 0 }}></input>
            </Modal>
        </div>
    )
}

export default ProductSearchForm;
